import React, { useState, useEffect } from 'react';

import Cookies from 'universal-cookie';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useRouter } from 'next/router';

import { request } from '../../shared/apis/utils';
import { COOKIE_DOMAIN } from '../../shared/helpers/utils';

import Link from '../../components/Link';
import Img from '../../components/Img';
import Hero from '../../components/PageComponents/Hero/Hero';
import BaseLayout from '../../components/Layout/BaseLayout';
import Button from '../../components/Button/Button';
import Grid, { gridStyles } from '../../components/Grid/Grid';
import TextInput from '../../components/Form/Input/TextInput';
import Password from '../../components/Form/Password/Password';
import Loader from '../../components/Loader/Loader';
import Logo from '../../components/Logo/Logo';

import Styles from './Login.module.scss';

const cookies = new Cookies();

const LoginPage = ({ initialUsername }) => {
  const router = useRouter();
  const { query } = router;
  const [tokenExpired, setTokenExpired] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [loadingWithToken, setLoadingWithToken] = useState(false);
  const [username, setUsername] = useState({ value: initialUsername, valid: true });
  const [password, setPassword] = useState({ value: '', valid: true, error: null });

  useEffect(() => {
    if (query?.clear) {
      cookies.remove('token', { path: '/' });
    }
    if (query?.expired) {
      setTokenExpired(true);
    }

    // if (cookies.get('token')) {
    //   setLoadingWithToken(true);
    //   setTimeout(() => {
    //     goToNext();
    //   }, 1000);

    //   setTimeout(() => {
    //     setLoadingWithToken(false);
    //   }, 2000);
    // }
  }, []);

  const goToNext = () => {
    if (query?.next) {
      window.location.href = decodeURIComponent(query.next);
    } else {
      window.location.href = `${process.env.NEXT_PUBLIC_APP_URL}/dashboard`;
    }
  };

  const handlePasswordKeyPress = (e) => {
    if (e.which === 13) {
      handleSubmit();
    }
  };

  const handleSubmit = (event) => {
    setLoading(true);
    const user = {
      username: username.value,
      password: password.value,
    };

    try {
      // Identify User Block
    } catch (err) {
      console.error(err);
    }

    request
      .post(process.env.NEXT_PUBLIC_LOGIN_URL, user)
      .then(({ data: { key } }) => {
        cookies.set('token', key, COOKIE_DOMAIN);
        goToNext();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        try {
          setPassword({
            value: password.value,
            valid: false,
            error: error.response.data.nonFieldErrors[0],
          });
        } catch (e) {}

        try {
          setPassword({
            value: password.value,
            valid: false,
            error: error.response.data.password[0],
          });
        } catch (e) {}
      });
  };

  return (
    <BaseLayout
      pageHead={{
        title: `Login | ${process.env.NEXT_PUBLIC_SITE_NAME}`,
      }}>
      <div className={Styles.container}>
        <Hero parent={Hero.Page.LOGIN} wave>
          <div className={Styles.contentWrapper}>
            <div className={Styles.introContainer}>
              <Img src={`/images/workingHome.png`} className={Styles.introImage} />
            </div>
            <div className={Styles.formContainer}>
              <Link to='/' className={Styles.logoContainer}>
                <Logo className={Styles.logo} type={Logo.Type.WHITE} />
              </Link>
              <div className={Styles.card}>
                <div className={Styles.header}>
                  <div className={Styles.headline}>{`Login`}</div>
                  {/*{loadingWithToken ? (
                    <p className={Styles.confirmMessage}>{`Redirecting you to the dashboard...`}</p>
                  ) : (*/}
                  <p
                    className={
                      Styles.description
                    }>{`Welcome back to ${process.env.NEXT_PUBLIC_SITE_NAME}`}</p>
                  {/*)}*/}

                  {tokenExpired && (
                    <p className={Styles.errorMessage}>
                      {`Your login details have expired. Please login again.`}
                    </p>
                  )}
                </div>
                <div className={Styles.inputContainer}>
                  <TextInput
                    name='username'
                    label='Email Address'
                    error={!username.valid && `Enter your email address.`}
                    value={username.value}
                    onChange={(e) =>
                      setUsername({ value: e.target.value, valid: e.target.value.length >= 1 })
                    }
                  />
                </div>
                <div className={Styles.inputContainer}>
                  <Password
                    name='password'
                    value={password.value}
                    error={!password.valid && (password.error || `Enter your password.`)}
                    placeholder={`Password`}
                    onKeyPress={handlePasswordKeyPress}
                    onChange={(e) =>
                      setPassword({ value: e.target.value, valid: e.target.value.length >= 1 })
                    }
                  />
                </div>
                <Grid
                  columns={2}
                  className={classNames(gridStyles.containerCenter, Styles.loginActionsContainer)}>
                  <Grid.Item style={{ textAlign: 'left' }}>
                    <a href='/reset' className={Styles.forgotPwd}>
                      {`Forgot Password`}
                    </a>
                  </Grid.Item>
                  <Grid.Item>
                    <Button
                      className={Styles.loginButton}
                      name='submit'
                      type={Button.Type.BLUE}
                      size={Button.Size.FULLWIDTH}
                      onClick={handleSubmit}>
                      {(loading && (
                        <Loader color={Loader.Color.WHITE} size={Loader.Size.SMALL} />
                      )) ||
                        `Log In`}
                    </Button>
                  </Grid.Item>
                </Grid>
              </div>
              <div className={Styles.signup}>
                <span>
                  {`No account yet? Sign up as a `}
                  <Link to='/talents/register' className={Styles.signupLink}>
                    {`Talent`}
                  </Link>
                  {` or `}
                  <Link to='/company/register' className={Styles.signupLink}>
                    {`Company`}
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </Hero>
      </div>
    </BaseLayout>
  );
};

export default LoginPage;

export const getServerSideProps = async ({ req, res, query: { next, username, email } }) => {
  // To get access to cookies on the server we use a different package, but we can only do so on SSR
  const Cookies = require('cookies');
  const cookies = new Cookies(req, res);
  const token = cookies.get('token');

  if (token && res) {
    res.writeHead(302, {
      Location: next ? decodeURIComponent(next) : `${process.env.NEXT_PUBLIC_APP_URL}/dashboard`,
    });
    res.end();
  }
  return {
    props: {
      initialUsername: username || email || '',
    },
  };
};
